<template>
  <div class="operator registration-page">
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <strong class="card-title mr-3">
              {{ $t("facilityRegistrationPage.pageTitle") }}
            </strong>
          </CCardHeader>
          <CCardBody class="px-5">
            <h4 class="sub-title">
              {{ $t("facilityRegistrationPage.facilityInformation") }}
            </h4>
            <CForm class="submit_form">
              <CRow>
                <CCol class="label"
                  ><strong class="required-mark">{{
                    $t("facilityRegistrationPage.name")
                  }}</strong></CCol
                >
                <CCol>
                  <CInput v-model="addFacility.name" />
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.addFacility.name.$dirty &&
                      !$v.addFacility.name.required
                    "
                  >
                    {{
                      $t("validations.required", {
                        vName: $t("facilityRegistrationPage.name"),
                      })
                    }}
                  </p>
                </CCol>
              </CRow>
              <CRow>
                <CCol class="label"
                  ><strong class="required-mark">{{
                    $t("facilityRegistrationPage.pronunciation")
                  }}</strong></CCol
                >
                <CCol>
                  <CInput v-model="addFacility.pronunciation" />
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.addFacility.pronunciation.$dirty &&
                      !$v.addFacility.pronunciation.required
                    "
                  >
                    {{
                      $t("validations.required", {
                        vName: $t("facilityRegistrationPage.pronunciation"),
                      })
                    }}
                  </p>
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.addFacility.pronunciation.$dirty &&
                      !$v.addFacility.pronunciation.isKana
                    "
                  >
                    {{
                      $t("validations.kana", {
                        vName: $t("facilityRegistrationPage.name"),
                      })
                    }}
                  </p>
                </CCol>
              </CRow>
              <CRow>
                <CCol class="label"
                  ><strong>{{
                    $t("facilityRegistrationPage.description")
                  }}</strong></CCol
                >
                <CCol>
                  <CTextarea class="mb-2" v-model="addFacility.desciption" />
                </CCol>
              </CRow>
              <CRow>
                <CCol class="label"
                  ><strong class="required-mark">{{
                    $t("facilityRegistrationPage.telephone")
                  }}</strong></CCol
                >
                <CCol>
                  <CInput
                    v-model="addFacility.telephone"
                    @blur="onFormBlur('telephone')"
                  />
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.addFacility.telephone.$dirty &&
                      !$v.addFacility.telephone.required
                    "
                  >
                    {{
                      $t("validations.required", {
                        vName: $t("facilityRegistrationPage.telephone"),
                      })
                    }}
                  </p>
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.addFacility.telephone.$dirty &&
                      !$v.addFacility.telephone.isIntDsh
                    "
                  >
                    {{
                      $t("validations.isIntDsh", {
                        vName: $t("facilityRegistrationPage.telephone"),
                      })
                    }}
                  </p>
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.addFacility.telephone.$dirty &&
                      !$v.addFacility.telephone.maxLength
                    "
                  >
                    {{
                      $t("validations.maxLength", {
                        vName: $t("facilityRegistrationPage.telephone"),
                        vMax:
                          $v.addFacility.telephone.$params.maxLength.max - 1,
                      })
                    }}
                  </p>
                </CCol>
              </CRow>
              <CRow>
                <CCol class="label"
                  ><strong>{{
                    $t("facilityRegistrationPage.fax")
                  }}</strong></CCol
                >
                <CCol>
                  <CInput
                    v-model="addFacility.fax"
                    @blur="onFormBlur('fax')"
                  />
                </CCol>
              </CRow>
              <CRow>
                <CCol class="label"
                  ><strong>{{
                    $t("facilityRegistrationPage.url")
                  }}</strong></CCol
                >
                <CCol>
                  <CInput v-model="addFacility.url" />
                </CCol>
              </CRow>
              <CRow>
                <CCol class="label"
                  ><strong>{{
                    $t("facilityRegistrationPage.email")
                  }}</strong></CCol
                >
                <CCol>
                  <CInput v-model="addFacility.email" />
                </CCol>
              </CRow>
              <CRow>
                <CCol class="label"
                  ><strong class="required-mark">{{
                    $t("facilityRegistrationPage.zipcode")
                  }}</strong></CCol
                >
                <CCol>
                  <CInput 
                    v-model="addFacility.zipcode"
                    @blur="onFormBlur('zipcode')"
                  />
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.addFacility.zipcode.$dirty &&
                      !$v.addFacility.zipcode.required
                    "
                  >
                    {{
                      $t("validations.required", {
                        vName: $t("facilityRegistrationPage.zipcode"),
                      })
                    }}
                  </p>
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.addFacility.zipcode.$dirty &&
                      !$v.addFacility.zipcode.isIntDsh
                    "
                  >
                    {{
                      $t("validations.isIntDsh", {
                        vName: $t("facilityRegistrationPage.zipcode"),
                      })
                    }}
                  </p>
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.addFacility.zipcode.$dirty &&
                      !$v.addFacility.zipcode.maxLength
                    "
                  >
                    {{
                      $t("validations.maxLength", {
                        vName: $t("facilityRegistrationPage.zipcode"),
                        vMax: $v.addFacility.zipcode.$params.maxLength.max,
                      })
                    }}
                  </p>
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.addFacility.zipcode.$dirty &&
                      !$v.addFacility.zipcode.minLength
                    "
                  >
                    {{
                      $t("validations.minLength", {
                        vName: $t("facilityRegistrationPage.zipcode"),
                        vMin: $v.addFacility.zipcode.$params.minLength.min,
                      })
                    }}
                  </p>
                </CCol>
                <CCol lg="4">
                  <CButton color="success" @click="getAddress()"
                    ><i class="icon cil-location-pin"></i> {{$t("common.addressSearchButton")}}</CButton
                  >
                </CCol>
              </CRow>
              <CRow>
                <CCol class="label"
                  ><strong class="required-mark">{{
                    $t("facilityRegistrationPage.address1")
                  }}</strong></CCol
                >
                <CCol>
                  <CInput
                    v-model="addFacility.address1"
                    :placeholder="$t('facilityEditPage.address1Holder')"
                  />
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.addFacility.address1.$dirty &&
                      !$v.addFacility.address1.required
                    "
                  >
                    {{
                      $t("validations.required", {
                        vName: $t("facilityRegistrationPage.address1"),
                      })
                    }}
                  </p>
                </CCol>
              </CRow>
              <CRow>
                <CCol class="label"
                  ><strong>{{
                    $t("facilityRegistrationPage.address2")
                  }}</strong></CCol
                >
                <CCol>
                  <CInput
                    class="mb-2"
                    v-model="addFacility.address2"
                    :placeholder="$t('facilityEditPage.address2Holder')"
                  />
                </CCol>
              </CRow>
              <CButton
                class="px-5 mt-4"
                color="info"
                @click="$v.addFacility.$invalid ? requiredFd() : confirmM()"
                v-bind:class="$v.addFacility.$invalid ? 'inactive' : 'active'"
              >
                <i class="cil-task"></i> {{ $t("common.check") }}
              </CButton>
            </CForm>
          </CCardBody>
          <CCardFooter>
            <!-- #region 戻るボタン -->
            <CButton color="secondary" @click="onBackClicked()"
              ><i class="icon cil-arrow-left mr-2"></i
              >{{ $t("common.back") }}</CButton
            >
            <!-- #endregion 戻るボタン -->
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
    <CModal
      color="info"
      size="lg"
      :closeOnBackdrop="false"
      :centered="true"
      :show.sync="checkModal"
    >
      <CForm class="submit_form">
        <CRow>
          <CCol class="label"
            ><strong>{{ $t("facilityRegistrationPage.name") }}</strong></CCol
          >
          <CCol
            ><span> {{ addFacility.name }} </span></CCol
          >
        </CRow>
        <CRow>
          <CCol class="label"
            ><strong>{{
              $t("facilityRegistrationPage.pronunciation")
            }}</strong></CCol
          >
          <CCol
            ><span>{{ addFacility.pronunciation }}</span>
          </CCol>
        </CRow>
        <CRow>
          <CCol class="label"
            ><strong>{{
              $t("facilityRegistrationPage.description")
            }}</strong></CCol
          >
          <CCol
            ><span>{{ addFacility.desciption }}</span>
          </CCol>
        </CRow>
        <CRow>
          <CCol class="label"
            ><strong>{{
              $t("facilityRegistrationPage.telephone")
            }}</strong></CCol
          >
          <CCol
            ><span>{{ addFacility.telephone }}</span></CCol
          >
        </CRow>
        <CRow>
          <CCol class="label"
            ><strong>{{ $t("facilityRegistrationPage.fax") }}</strong></CCol
          >
          <CCol
            ><span>{{ addFacility.fax }}</span></CCol
          >
        </CRow>
        <CRow>
          <CCol class="label"
            ><strong>{{ $t("facilityRegistrationPage.url") }}</strong></CCol
          >
          <CCol
            ><span>{{ addFacility.url }}</span></CCol
          >
        </CRow>
        <CRow>
          <CCol class="label"
            ><strong>{{ $t("facilityRegistrationPage.email") }}</strong></CCol
          >
          <CCol
            ><span>{{ addFacility.email }}</span></CCol
          >
        </CRow>
        <CRow>
          <CCol class="label"
            ><strong>{{ $t("facilityRegistrationPage.zipcode") }}</strong></CCol
          >
          <CCol
            ><span>{{ addFacility.zipcode }}</span></CCol
          >
        </CRow>
        <CRow>
          <CCol class="label"
            ><strong>{{
              $t("facilityRegistrationPage.address1")
            }}</strong></CCol
          >
          <CCol
            ><span>{{ addFacility.address1 }}</span></CCol
          >
        </CRow>
        <CRow>
          <CCol class="label"
            ><strong>{{
              $t("facilityRegistrationPage.address2")
            }}</strong></CCol
          >
          <CCol
            ><span>{{ addFacility.address2 }}</span></CCol
          >
        </CRow>
      </CForm>
      <template #header> {{ $t("common.check") }} </template>
      <template #footer>
        <CButton @click="checkModal = false" color="secondary">
          <i class="icon cil-x"></i> {{ $t("common.cancel") }}
        </CButton>
        <CButton color="success" @click="onRegisterClicked()">
          <i class="icon cil-save mr-1"></i> {{ $t("common.register") }}
        </CButton>
      </template>
    </CModal>

    <!-- Success -->
    <CModal
      color="success"
      :closeOnBackdrop="false"
      :centered="true"
      :show.sync="successModal"
    >
      {{ $t("commonMessage.updateSuccessText") }}
      <template #header>
        {{ $t("commonMessage.updateSuccessHeader") }}
      </template>
      <template #footer>
        <CButton @click="successModal = false" color="secondary">
          <i class="icon cil-x"></i> {{ $t("common.close") }}
        </CButton>
      </template>
    </CModal>

    <!-- Error -->
    <CModal
      color="danger"
      closeOnBackdrop
      :centered="true"
      :show.sync="errorModal"
    >
      <p>{{ errorMessage }}</p>
      <template #header>
        <h4 class="modal-title">{{ $t("common.error") }}</h4>
      </template>
      <template #footer>
        <CButton @click="errorModal = false" color="secondary">
          <i class="icon cil-x"></i> {{ $t("common.close") }}
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import axios from "axios";

import {
  required,
  minLength,
  maxLength,
  helpers,
} from "vuelidate/lib/validators";

const zipcode_url =
  "https://jxamy7mdqg.execute-api.ap-northeast-1.amazonaws.com/api/";

export default {
  name: "AddNewYado",
  data() {
    return {
      successModal: false,
      errorModal: false,
      checkModal: false,
      errorMessage: "",
      addFacility: {
        telephone: "",
        fax: "",
        url: "",
        email: "",
        zipcode: "",
        latitude: null,
        longtitude: null,
        name: "",
        pronunciation: "",
        desciption: null,
        address1: "",
        address2: "",
        language: "",
      },
    };
  },
  validations() {
    const isKana = helpers.regex("isKana", /^([ァ-ヶ][ァ-ヶー・\s]*)$/);
    const isIntDsh = helpers.regex("isIntDsh", /^[0-9-]*$/);
    return {
      addFacility: {
        telephone: { required, isIntDsh, maxLength: maxLength(22) },
        zipcode: {
          required,
          minLength: minLength(7),
          isIntDsh,
          maxLength: maxLength(7),
        },
        name: { required, maxLength: maxLength(100) },
        pronunciation: { required, isKana, maxLength: maxLength(100) },
        address1: { required, maxLength: maxLength(100) },
      },
    };
  },
  methods: {
    /**フォームのフォーカスアウト時 */
    onFormBlur(key) {
      this.removeHyphen(key);
    },

    /** 戻るボタン押下 */
    onBackClicked() {
      this.backPage();
    },

    backPage() {
      this.$router.go(-1);
    },

    /**登録押下 */
    onRegisterClicked(){
      this.reqPost();
    },

    /** Form: highlight if not valid */
    requiredFd() {
      this.$v.addFacility.$touch();
    },
    /** Form: button check modal */
    confirmM() {
      this.checkModal = true;
    },

    /** "-"排除した値をセット
     * @param key
    */
    removeHyphen(key){
      const hyphenChar = "-";
      this.addFacility[key] = this.addFacility[key].split(hyphenChar).join('');
    },

    // #region request

    /**新規登録 */
    reqPost() {
      this.addFacility.language = this.$i18n.locale;
      const body = this.addFacility;

      const callback = () => {
        this.checkModal = false;
        this.successModal = true;
        this.addFacility = {};
      }

      const errorCallback = (err) => {
        this.errorMessage = err;
        this.errorModal = true;
      }

      this.reqPostHotel(body,callback,errorCallback);
    },

    getAddress: function () {
      // Get address by zipcode
      const urlRequest =
        zipcode_url + "?postcode=" + this.addFacility.zipcode;

      axios
        .get(urlRequest)
        .then((resp) => {
          console.log("resp.data: ", resp.data);
          this.addFacility.address1 = `${resp.data.PrefecturesName}${resp.data.CityName}${resp.data.TownName}`;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // #endregion request
  },
  beforeMount() {},
};
</script>
